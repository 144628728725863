
// Source: https://github.com/wemake-services/vue-material-input/blob/master/src/components/MaterialInput.vue
import { defineComponent, getCurrentInstance, reactive, toRefs } from 'vue'
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    id: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    },
    autoComplete: {
      type: String,
      default: 'off'
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 10000
    },
    step: {
      type: Number,
      default: 1
    },
    minlength: {
      type: Number,
      default: 0
    },
    maxlength: {
      type: Number,
      default: 20
    },
    validateEvent: {
      type: Boolean,
      default: true
    }
  },
  emits: ['inputVal', 'el.form.change', 'focus', 'blur'],
  setup(props, contex) {
    const state = reactive({
      focus: false
    })
    const { ctx } = getCurrentInstance() as any
    // watch(() => state.valueCopy, (value) => {
    //   state.valueCopy = value
    // })

    const computedClasses = () => {
      return {
        'material--active': state.focus,
        'material--disabled': props.disabled
        // 'material--raised': Boolean(state.focus || state.valueCopy)
      }
    }

    const filledPlaceholder = () => {
      if (state.focus) {
        return props.placeholder
      }
      return ''
    }
    const handleInput = (event: KeyboardEvent) => {
      const value = (event.target as HTMLInputElement).value
      contex.emit('inputVal', value)
      if (ctx.$parent.$options.name === 'ElFormItem') {
        if (props.validateEvent) {
          // See https://github.com/ElemeFE/element/blob/dev/packages/form/src/form-item.vue#L293
          // eslint-disable-next-line vue/custom-event-name-casing
          contex.emit('el.form.change', [value])
        }
      }
    }

    const handleFocus = (event: FocusEvent) => {
      state.focus = true
      contex.emit('focus', event)
    }

    const handleBlur = (event: FocusEvent) => {
      state.focus = false
      contex.emit('blur', event)
      if (ctx.$parent.$options.name === 'ElFormItem') {
        if (props.validateEvent) {
          // See https://github.com/ElemeFE/element/blob/dev/packages/form/src/form-item.vue#L292
          // eslint-disable-next-line vue/custom-event-name-casing
          // ctx.$parent.$emit('el.form.blur', [state.valueCopy])
        }
      }
    }
    return {
      ...toRefs(state),
      computedClasses,
      filledPlaceholder,
      handleInput,
      handleFocus,
      handleBlur
    }
  }
})
