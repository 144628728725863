
import { defineComponent } from 'vue'
import MaterialInput from '@/components/material-input/Index.vue'
import PanThumb from '@/components/pan-thumb/Index.vue'
import Mallki from '@/components/text-hover-effect/Mallki.vue'
import DropdownMenu from '@/components/dropdown-menu/Index.vue'
export default defineComponent({
  components: {
    MaterialInput, PanThumb, Mallki, DropdownMenu
  },
  setup() {
    const validateLength = (rule: any, value: string, callback: Function) => {
      if (value.length !== 6) {
        callback(new Error('请输入六个字符'))
      } else {
        callback()
      }
    }
    const demo = {
      title: ''
    }
    const demoRules = {
      title: [{ validator: validateLength, trigger: 'change' }]
    }
    const articleList = [
      { title: '基础篇', href: 'https://juejin.im/post/59097cd7a22b9d0065fb61d2' },
      { title: '登录权限篇', href: 'https://juejin.im/post/591aa14f570c35006961acac' },
      { title: '实战篇', href: 'https://juejin.im/post/593121aa0ce4630057f70d35' },
      { title: 'vue-admin-template 篇', href: 'https://juejin.im/post/595b4d776fb9a06bbe7dba56' },
      { title: 'v4.0 篇', href: 'https://juejin.im/post/5c92ff94f265da6128275a85' },
      { title: '优雅的使用 icon', href: 'https://juejin.im/post/59bb864b5188257e7a427c09' }
    ]
    return {
      validateLength, demo, demoRules, articleList
    }
  }
})
